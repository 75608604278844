function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
/* eslint-disable react/jsx-props-no-spreading */
import { h, Component } from 'preact';
import RecordButton from "./RecordButton.js";
import SubmitButton from "./SubmitButton.js";
import StopWatch from "./StopWatch.js";
import StreamStatus from "./StreamStatus.js";
class RecorderScreen extends Component {
  constructor() {
    super(...arguments);
    this.videoElement = null;
  }
  componentWillUnmount() {
    const {
      onStop
    } = this.props;
    onStop();
  }
  render() {
    const {
      recording,
      stream: videoStream,
      recordedVideo
    } = this.props;
    const videoProps = {
      playsinline: true
    };

    // show stream
    if (recording || !recordedVideo && !recording) {
      videoProps.muted = true;
      videoProps.autoplay = true;
      videoProps.srcObject = videoStream;
    }

    // show preview
    if (recordedVideo && !recording) {
      videoProps.muted = false;
      videoProps.controls = true;
      videoProps.src = recordedVideo;

      // reset srcObject in dom. If not resetted, stream sticks in element
      if (this.videoElement) {
        this.videoElement.srcObject = null;
      }
    }
    return h("div", {
      className: "uppy uppy-ScreenCapture-container"
    }, h("div", {
      className: "uppy-ScreenCapture-videoContainer"
    }, h(StreamStatus, this.props), h("video", _extends({
      ref: videoElement => {
        this.videoElement = videoElement;
      },
      className: "uppy-ScreenCapture-video"
    }, videoProps)), h(StopWatch, this.props)), h("div", {
      className: "uppy-ScreenCapture-buttonContainer"
    }, h(RecordButton, this.props), h(SubmitButton, this.props)));
  }
}
export default RecorderScreen;